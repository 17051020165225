@font-face {
  font-family: "Roboto";
  src: url("Roboto-Black.woff2") format('woff2'),
      url("Roboto-Black.woff") format('woff'),
      url("Roboto-Black.ttf") format('ttf');
      font-style: normal;
      font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-BlackItalic.woff2") format('woff2'),
      url("Roboto-BlackItalic.woff") format('woff'),
      url("Roboto-BlackItalic.ttf") format('ttf');
      font-style: italic;
      font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Bold.woff2") format('woff2'),
      url("Roboto-Bold.woff") format('woff'),
      url("Roboto-Bold.ttf") format('ttf');
      font-style: normal;
      font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-BoldItalic.woff2") format('woff2'),
      url("Roboto-BoldItalic.woff") format('woff'),
      url("Roboto-BoldItalic.ttf") format('ttf');
      font-style: italic;
      font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Medium.woff2") format('woff2'),
      url("Roboto-Medium.woff") format('woff'),
      url("Roboto-Medium.ttf") format('ttf');
      font-style: normal;
      font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-MediumItalic.woff2") format('woff2'),
      url("Roboto-MediumItalic.woff") format('woff'),
      url("Roboto-MediumItalic.ttf") format('ttf');
      font-style: italic;
      font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Regular.woff2") format('woff2'),
      url("Roboto-Regular.woff") format('woff'),
      url("Roboto-Regular.ttf") format('ttf');
      font-style: normal;
      font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Italic.woff2") format('woff2'),
      url("Roboto-Italic.woff") format('woff'),
      url("Roboto-Italic.ttf") format('ttf');
      font-style: italic;
      font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Light.woff2") format('woff2'),
      url("Roboto-Light.woff") format('woff'),
      url("Roboto-Light.ttf") format('ttf');
      font-style: normal;
      font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-LightItalic.woff2") format('woff2'),
      url("Roboto-LightItalic.woff") format('woff'),
      url("Roboto-LightItalic.ttf") format('ttf');
      font-style: italic;
      font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Thin.woff2") format('woff2'),
      url("Roboto-Thin.woff") format('woff'),
      url("Roboto-Thin.ttf") format('ttf');
      font-style: normal;
      font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-ThinItalic.woff2") format('woff2'),
      url("Roboto-ThinItalic.woff") format('woff'),
      url("Roboto-ThinItalic.ttf") format('ttf');
      font-style: italic;
      font-weight: 100;
}